import React from 'react'
import Web3 from 'web3';
import { useState, useRef, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Logo } from '../../assets/index';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";

function Header() {



    const web3 = new Web3(window.ethereum);
    const [account, setAccount] = useState(localStorage.getItem('walletAddress'));
    const [isConnected, setIsConnected] = useState(false);

      //Connect Wallet
      const connectWallet = async () => {
        let provider = window.ethereum
        if (!provider) {
            alert('Metamask not installed');
        }
        else {
            provider.request({ method: 'eth_requestAccounts' }).then(accounts => {
                console.log(accounts);
                setAccount(accounts[0])
                localStorage.setItem('walletAddress', accounts[0])
                console.log("Wallet Connected")
                setIsConnected(true)
            }).catch(error => {
                console.log(error)
            })
        }
    }

    return (
        <Navbar expand="lg" className="navbar-light">
            <Container>
                <Navbar.Brand to="/"><img src={Logo} className='Logo' /></Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Link to="/">Home</Link>
                        <Link to="./Aboutus">About Us</Link>
                        <a href="https://larrytalleys.ad-wize.xyz/BlueButterfly-NFT-Whitepaper-Content.pdf">Whitepaper</a>
                        <Link to="./Support">Support</Link>
                        {/* <Link to="./Mint">Mint</Link> */}
                    </Nav>
                    {
                        isConnected ? <>
                         {/* <h3 className='connect-btn-address' >{account}</h3> */}
                        </> : 
                        <Button className='connect-btn' >Connect Wallet</Button>

                    }
                    
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Header